// file path: src\Components\AuthGuard.tsx

import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../services/auth.service';
import { setUserData, setToken, setUserDataLoading, setSubscription } from '../stores/auth.slice';
import LoaderComponent from './LoaderComponent';
import { RootState, AppDispatch } from '../stores/auth.store'; // Adjust the path as needed

// Lazy load the route components
const PrivateRoutes = React.lazy(() => import('../Routes/PrivateRoutes'));
const PublicRoutes = React.lazy(() => import('../Routes/PublicRoutes'));

const AuthGuard: React.FC = () => {
    const token = useSelector((state: RootState) => state.auth.token);
    const userDataLoading = useSelector((state: RootState) => state.auth.userDataLoading);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (token) {
                dispatch(setUserDataLoading(true));
                getProfile(token).then((res) => {
                    if (res.data) {
                        const { subscription } = res.data
                        if (subscription) {
                            dispatch(setSubscription(subscription));
                        }
                        dispatch(setUserData(res.data));
                    }
                }).catch((err) => {
                    if (err.response?.status === 401) {
                        window.localStorage.removeItem('token');
                        dispatch(setUserData(null));
                        dispatch(setToken(null));
                    } else {
                        console.error("Get User Details Error", err);
                    }
                }).finally(() => {
                    dispatch(setUserDataLoading(false));
                });
            }
        }, 1)
        return () => {
            clearTimeout(timer)
        }
    }, [token]);

    if (userDataLoading) {
        return <LoaderComponent msg="Verifying Session..." />;
    }

    return (
        <Suspense fallback={<LoaderComponent msg="Loading Assets..." />}>
            {!token ? <PublicRoutes /> : <PrivateRoutes />}
        </Suspense>
    );
};

export default AuthGuard;
