// file path: src\stores\auth.slice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User, { UserRole } from '../Types/user.type';
import Subscription from '../Types/subscription.type';


interface AuthState {
  user: (User & { roles: UserRole[] }) | null;
  userDataLoading: boolean;
  token: string | null;
  subscription?: Subscription;
}

const initialState: AuthState = {
  user: null,
  userDataLoading: false,
  token: window.localStorage.getItem('token'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.avatar = action.payload;
      }
    },
    setUserDataLoading: (state, action: PayloadAction<boolean>) => {
      state.userDataLoading = action.payload;
    },
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload;
    }
  },
});

export const { setToken, setUserData, setUserDataLoading, setAvatar, setSubscription } = authSlice.actions;

export default authSlice.reducer;
