// file path: src\config.ts

export const appSubDir = '/'
export const baseUrl = process.env.REACT_APP_BASE_URL || "http://test.workowl.io/"
export const apiBaseUrl = baseUrl + "api/"
export const storageBaseUrl = baseUrl + "storage/"

// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


export const stripeKey = process.env.REACT_APP_STRIPE_KEY