// file path: src\Components\CustomSpinnerIcon.tsx

import React from 'react';

const CustomSpinnerIcon: React.FC = () => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="50" cy="10" r="4" fill="#1890ff" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="70" cy="15" r="6" fill="#1890ff" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="90" cy="30" r="8" fill="#1890ff" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#22C55E" fontSize="30" fontWeight="700" fontFamily="Arial, sans-serif">
            WO
        </text>
    </svg>
);

export const indicatorIcon = <CustomSpinnerIcon />;

export const indicatorIconWithDotHolder = (
    <div className="ant-spin-dot-holder">
        <CustomSpinnerIcon />
    </div>
);

export default indicatorIcon;
