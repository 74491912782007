// file path: src\stores\auth.store.ts

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

// Define the RootState and AppDispatch types based on the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
