// file path: src\services\auth.service.ts

import { apiBaseUrl, storageBaseUrl } from "../config";
import axios, { AxiosResponse } from "axios";
import User, { ProfileResponse, UserSimple, UserUpdate } from "../Types/user.type";

export interface RegisterFormValues {
  f_name: string;
  l_name: string;
  company_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export interface ForgotPasswordFormValues {
  email: string;
}
export interface ResetPasswordFormValues {
  otp: string;
  password: string;
  password_confirmation: string;
}
export interface ChangePassword {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}
export interface TokenResponse {
  message: string;
  token: string;
}
export interface OkResponse {
  message: string;
}
export interface AuthHeader {
  headers: {
    Authorization: string
  }
}


export function getAvatarUrl(avatarFileName: string): string {
  return `${storageBaseUrl}avatars/${avatarFileName}.webp`;
}

export function getAuthHeader(): AuthHeader {
  return { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } };
}

export function hasRole(user: User | null, roleName: string): boolean {
  return user ? user.roles.some(role => role.name === roleName) : false;
}

export function getFullName(user: UserSimple): string {
  return user.f_name + (user.l_name ? " " + user.l_name : "");
}

export function getApiPrefix(role: string): string {
  if (!['super-admin', 'client', 'team'].includes(role)) {
    return "";
  }
  return role === 'client' ? "company/" : role === 'team' ? "team/" : "";
}

export function getRole(user: User): string {
  return user && user.roles.length > 0 ? user.roles[0].display_name : '';
}

export function getRoleName(user: User | null): string {
  return user && user.roles.length > 0 ? user.roles[0].name : '';
}

export function login(email: string, password: string): Promise<AxiosResponse<{ token: string }>> {
  return axios.post(`${apiBaseUrl}login`, { email, password });
}

export function register(dataToPost: RegisterFormValues): Promise<AxiosResponse<TokenResponse>> {
  return axios.post(`${apiBaseUrl}register`, dataToPost);
}

export function forgotPassword(dataToPost: ForgotPasswordFormValues): Promise<AxiosResponse<TokenResponse>> {
  return axios.post(`${apiBaseUrl}forgot-password`, dataToPost);
}
export function resetPassword(dataToPost: ResetPasswordFormValues & { token: string; }): Promise<AxiosResponse<OkResponse>> {
  return axios.post(`${apiBaseUrl}reset-password`, dataToPost);
}

export function getProfile(token: string): Promise<AxiosResponse<ProfileResponse | null>> {
  return axios.get(`${apiBaseUrl}user`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
}

export function updateProfile(values: UserUpdate): Promise<AxiosResponse<User | null>> {
  return axios.put(`${apiBaseUrl}user`, values, getAuthHeader());
}

export function uploadAvatar(formData: FormData): Promise<AxiosResponse<string>> {
  return axios.post(`${apiBaseUrl}avatar`, formData, getAuthHeader());
}

export function changePassword(values: ChangePassword): Promise<AxiosResponse> {
  return axios.post(`${apiBaseUrl}password`, values, getAuthHeader());
}

export function logout(token: string): Promise<AxiosResponse> {
  return axios.post(`${apiBaseUrl}logout`, undefined, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
}
